import getLinkType from "@exp/scripts/helpers/get-link-type";
let nonQuickExitTimer, qualityViewTimer;
let user, environment, org, browser, app, device, sessionId, referrer, eventParams;
window.dataLayer = window.dataLayer || [];
const Exp = window.Exp || {};
Exp.pushPageView = pushPageView;
let content = {};
try {
  content = JSON.parse(document.getElementById("gtm-content").innerText);
  content.location = window.location.href;
} catch (err) {
  // no-op
}
export { browser, content, init as default, environment, org, pushFullPageAdEvent, pushFurtherReadingEvent, pushPageView, pushPodcastEvent, pushTapetEvent, pushArticleLinkClick, pushArticleScroll };
function init() {
  setupProperties();
  Exp.onConsentReady = Exp.onConsentReady || [];
  Exp.onConsentReady.push(() => {
    Exp.consentData = Exp.consentData || {};
    window.dataLayer.push({
      "gtm.start": Date.now(),
      event: "gtm.js"
    });
    pushPageView({
      content,
      user,
      environment,
      org,
      browser,
      device,
      sessionId,
      referrer,
      eventParams,
      ...Exp.consentData,
      ...(app && {
        app
      })
    });
    window.dispatchEvent(new Event("exp-didomiready"));
  });
}
function setupProperties() {
  const appUser = window.bnTracking && window.bnTracking.appData && window.bnTracking.appData.user;
  user = Exp.user ? {
    isLoggedIn: true,
    id: {
      value: Exp.user.id,
      system: "credentials"
    },
    isSubscriber: !!(Exp.user.entitlements && Exp.user.entitlements.length),
    subscriptionCodes: Exp.user.entitlements || [],
    ...(appUser && appUser)
  } : {
    isLoggedIn: !!Exp.signedIn,
    ...(appUser && appUser)
  };
  const appContent = window.bnTracking && window.bnTracking.appData && window.bnTracking.appData.content;
  content = {
    ...content,
    renderedSize: Exp.channel,
    referrer: document.referrer || undefined,
    ...(appContent && appContent)
  };
  if (content.userHasAccess) {
    // field only present on articles
    content.userHasAccess = window.Exp.userHasAccess;
  }
  const appEnvironment = window.bnTracking && window.bnTracking.appData && window.bnTracking.appData.environment;
  environment = {
    isProduction: Exp.isProduction,
    platform: {
      name: Exp.platformName
    },
    dataSource: Exp.isHybridTablet ? "app" : "web",
    isFailover: Exp.isFailover,
    ...(appEnvironment && appEnvironment)
  };
  if (window.bnTracking && window.bnTracking.appData) {
    app = window.bnTracking.appData.app;
    device = window.bnTracking.appData.device;
    sessionId = window.bnTracking.appData.sessionId;
    referrer = window.bnTracking.appData.referrer;
    eventParams = window.bnTracking.appData.eventParams;
  } else {
    const urlParams = new URLSearchParams(window.location.search);
    const entryPoint = urlParams.get("referrer");
    if (entryPoint) {
      eventParams = {
        entryPoint: `main-column-position-${entryPoint}`
      };
    }
  }
  org = {
    brandLine: Exp.platformName === "solveig" ? "Expressen" : "Lifestyle",
    brand: Exp.config && Exp.config.fullName || "Expressen",
    subBrand: Exp.config && Exp.config.fullName || "Expressen"
  };
  browser = {
    adblocker: Exp.adsBlocked ? "active" : "not found",
    cdnCookie: `${Exp.userGroup}`
  };
}
function pushPageView() {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (!window.dataLayer) return;
  clearTimeout(nonQuickExitTimer);
  clearTimeout(qualityViewTimer);
  Exp.consentData = {
    consent: getConsent(window)
  };
  window.dataLayer.push({
    event: "page view",
    ...data
  });
  nonQuickExitTimer = setTimeout(pushNonQuickExit, 5000);
  qualityViewTimer = setTimeout(pushQualityView, 15000);
  function pushNonQuickExit() {
    window.dataLayer.push({
      event: "non quick exit"
    });
  }
  function pushQualityView() {
    window.dataLayer.push({
      event: "quality view"
    });
  }
}
function pushPodcastEvent() {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let additionalData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let type = arguments.length > 2 ? arguments[2] : undefined;
  if (!window.dataLayer) return;
  const {
    title,
    show,
    episode_url: episodeUrl
  } = data;
  const podData = {
    media: {
      mediaType: "podcast",
      contentType: "content",
      title,
      mediaSection: getSectionsFromUrl(window.location.href),
      programName: show.title,
      episodeNumber: undefined,
      canonicalUrl: episodeUrl,
      position: undefined,
      streamingType: "recorded",
      startMethod: "manual play",
      publishTime: undefined,
      playerName: "Expressen podplayer",
      producer: show.author,
      partner: undefined,
      mediaLength: Math.round(additionalData.duration),
      mediaIndex: "1",
      interaction: {
        isInteraction: false,
        name: undefined
      },
      fullscreen: false,
      visible: true
    }
  };
  if (type === "start") {
    window.dataLayer.push({
      event: "podcast all start",
      media: {
        ...podData.media,
        eventType: "start",
        time: {
          percent: "0%",
          currentTime: 0,
          timePast: 0
        },
        interaction: {
          isInteraction: true,
          name: "play"
        },
        muted: additionalData.muted
      }
    });
  }
  if (type === "milestone") {
    window.dataLayer.push({
      event: "podcast content milestone",
      media: {
        ...podData.media,
        eventType: "milestone",
        time: {
          percent: `${additionalData.milestone}%`,
          currentTime: Math.round(additionalData.currentTime),
          timePast: undefined
        },
        interaction: {
          isInteraction: true,
          name: "play"
        },
        muted: additionalData.muted
      }
    });
  }
  if (type === "pulse") {
    window.dataLayer.push({
      event: "podcast content pulse",
      media: {
        ...podData.media,
        eventType: "pulse",
        time: {
          percent: undefined,
          currentTime: Math.round(additionalData.currentTime),
          timePast: undefined
        },
        interaction: {
          isInteraction: true,
          name: "play"
        },
        muted: additionalData.muted
      }
    });
  }
  function getSectionsFromUrl(url) {
    const urlObj = new URL(url);
    const sections = urlObj.pathname.split("/").filter(Boolean);
    if (sections.length > 1) {
      sections.pop();
    }
    return sections;
  }
}
function pushFullPageAdEvent(type) {
  if (type === "impression") {
    window.dataLayer.push({
      event: "fullscreen ad impression",
      eventParams: {
        category: "fullscreen ad"
      },
      engagement: {
        type: "impression",
        component: undefined
      }
    });
  }
  if (type === "close") {
    window.dataLayer.push({
      event: "fullscreen ad close",
      eventParams: {
        category: "fullscreen ad"
      },
      engagement: {
        type: "close",
        component: {
          position: "top right corner",
          name: "Stäng",
          type: "button"
        }
      }
    });
  }
  if (type === "scroll") {
    window.dataLayer.push({
      event: "fullscreen ad scroll",
      eventParams: {
        category: "fullscreen ad"
      },
      engagement: {
        type: "scroll",
        component: undefined
      }
    });
  }
}
function pushFurtherReadingEvent(_ref, type) {
  let {
    title,
    url,
    index,
    areaSource
  } = _ref;
  const componentConfig = {
    "read more slider": {
      name: "Andra har också läst",
      type: "slider"
    },
    "read more link": {
      name: "Läs mer",
      type: "hyperlink"
    }
  };
  const baseParams = {
    eventParams: {
      category: type === "impression" ? "slider" : "article",
      ...(areaSource && {
        areaSource
      })
    }
  };
  const engagement = type === "impression" ? {
    type: "impression",
    component: {
      position: "article bottom",
      ...componentConfig["read more slider"]
    }
  } : {
    type: "link click",
    component: {
      name: componentConfig[type].name,
      index: typeof index === "number" ? index.toString() : undefined,
      type: componentConfig[type].type
    },
    destination: {
      title,
      url,
      type: getLinkType(url)
    }
  };
  window.dataLayer.push({
    event: type === "impression" ? "slider impression" : "article link click",
    ...baseParams,
    engagement
  });
}
function pushArticleLinkClick(link) {
  window.dataLayer.push({
    event: "article link click",
    eventParams: {
      category: "article"
    },
    engagement: {
      type: "link click",
      destination: {
        url: link.href,
        type: getLinkType(link.href)
      }
    }
  });
}
function pushArticleScroll() {
  window.dataLayer.push({
    event: "article scroll",
    eventParams: {
      category: "article",
      scrollDepth: "foot",
      scrollMeasure: "element"
    }
  });
}
function pushTapetEvent(event) {
  return window.dataLayer.push(event);
}
function getConsent(window) {
  const vendorSdkIds = ["bonniernews-analys", "bonniernews-marknadsforing", "bn-local-forms", "bonniernewshealthcare-netdoktor", "bonniernewshealthcare-netdoktorpro", "bonniernews-reynolds", "bonniernews", "bn-netdoktorpro-bonniernews", "bn-netdoktor-bonniernews", "bn-personuppgiftsbitrade", "bn-expressen", "bonniernews-netdoktor-dk"];
  const {
    purposes = {},
    vendors = {}
  } = window.Didomi?.getCurrentUserStatus() ?? {};
  const vendorsEnabled = Object.values(vendors).filter(function () {
    let {
      enabled,
      id
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return enabled && vendorSdkIds.includes(id);
  }).map(function () {
    let {
      id
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return id;
  });
  const purposesEnabled = Object.values(purposes).filter(function () {
    let {
      enabled
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return enabled;
  }).map(function () {
    let {
      id
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return id;
  });
  return {
    purposesEnabled,
    vendorsEnabled
  };
}