import { checkConsentForVendorSync } from "../integration/didomi";
let customCss;
const requiredVendors = ["c:bn-facebook", "c:bn-instagram", "c:bn-youtube", "c:bn-twitter"];
window.liveReport = window.liveReport || [];
export default function setup(scope) {
  let colorScheme = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "light";
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(() => {
    const widgets = (scope || document).getElementsByClassName("live-report-widget");
    if (!widgets.length) return;
    const consent = requiredVendors.every(vendorId => checkConsentForVendorSync(vendorId));
    let loadWidget, options;
    if (window.Exp.experiments?.newLiveReport) {
      const adSlotName = window.CHANNEL_MOBILE ? "mob" : "articlemodule";
      window.liveReport.push(api => {
        api.init({
          ssr: true,
          adSlotName,
          apiHost: window.Exp.config.liveReportUrl,
          consent
        });
      });
      loadWidget = loadNewWidget;
    } else {
      customCss = customCss || window.Exp.ifragasattCustomCss[colorScheme];
      loadWidget = loadOldWidget;
      options = {
        customCss,
        consent
      };
    }
    for (let i = 0, len = widgets.length; i < len; i++) {
      loadWidget(widgets[i], options);
    }
  });
}
function loadNewWidget(widget) {
  const id = widget.dataset.resourceId;
  if (!id) return;
  window.liveReport.push(api => {
    api.load(widget, id);
  });
}
function loadOldWidget(widget, options) {
  const id = widget.dataset.resourceId;
  if (!id) return;
  fetch(`https://direkt.ifragasatt.se/load/?aId=${id}&customCss=${options.customCss}&userConsent=${!!options.consent}`).then(response => response.json()).then(data => {
    const script = document.createElement("script");
    script.appendChild(document.createTextNode(data.src));
    widget.appendChild(script);
  }).catch(() => 0);
}